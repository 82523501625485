.contacts {
    max-width: 1480px;
    margin: 140px auto ;
}

.contacts__title {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    line-height: 30.5px;
    color: #FFF;
    letter-spacing: 1px;
    text-align: left;
    margin: 0;
}

.contacts__title-span {
    display: inline-block;
    margin: 0;
}

.contacts__title-span::after {
    content: '';
    position: absolute;
    width: calc(100% - 237px);
    border-top: 2px solid #FFF;
    margin-top: .6em;
    margin-left: 32px;
    opacity: 0.13;
}

.contacts__map {
    margin: 40px 0 0;
    width: 100%;
    height: 360px;
}

.contacts__adress {
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    color: #FFF;
    margin: 24px 0 68px;
    text-align: center;
}

.contacts__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contacts__phone-numbers {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contacts__phone-number {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: #FFF;
    margin: 0;
    text-decoration: none;
}

.social__links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
    border-radius: 76px;
    padding: 9px 24px;
    transition: opacity 0.1s ease-in;
    cursor: pointer;
}

.social__link:hover {
    opacity: 0.7;
}

.social__link-container {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.social__link-link {
    text-decoration: none;
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
}

.social__link-image {
    width: 34px;
    height: 34px;
    margin: 0 16px 0 0;
}

@media screen and (max-width: 1560px) {
    .contacts {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 1015px) {
    .contacts__map {
        height: 300px;
    }

    .contacts__adress {
        font-size: 22px;
    }

    .contacts__phone-number {
        font-size: 28px;
    }

    .social__links {
        gap: 64px;
    }

    .social__link-image {
        width: 28px;
        height: 28px;
    }

    .social__link-link {
        font-size: 15px;
    }
}

@media screen and (max-width: 815px) {
    .contacts__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contacts__content {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .contacts__phone-numbers {
        gap: 20px;
    }

    .contacts__map {
        height: 250px;
    }

    .contacts__adress {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .contacts__phone-number:last-of-type {
        margin-bottom: 30px;
    }

    .social__links {
        gap: 30px;
        flex-direction: row;
    }
}

@media screen and (max-width: 590px) {
    .contacts__content {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .contacts__map {
        height: 250px;
    }

    .contacts__adress {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .contacts__phone-numbers {
        gap: 5px;
    }

    .contacts__phone-number:last-of-type {
        margin-bottom: 20px;
    }

    .contacts__phone-number {
        font-size: 22px;
    }

    .social__links {
        gap: 35px;
    }

    .social__link {
        padding: 7px 15px;
    }

    .social__link-image {
        width: 23px;
        height: 23px;
    }

    .social__link-link {
        font-size: 13px;
    }
}

@media screen and (max-width: 480px) {
    .contacts__map {
        height: 200px;
    }

    .contacts__adress {
        font-size: 14px;
        text-align: center;
    }

    .social__links {
        flex-direction: column;
    }
}

@media screen and (max-width: 610px) {
    .contacts {
        width: calc(100% - 30px);
    }

    .contacts__title-span {
        font-size: 18px;
    }

    .contacts__title-span::after {
        margin-top: .8em;
        width: calc(100% - 190px);
    }
}