.header {
    max-width: 1480px;
    margin: 30px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    color: #FFF;
    text-decoration: none;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
}

.header__list {
    list-style: none;
    display: flex;
    gap: 51px;
    padding: 0;
    margin: 0;
}

.header__list-el-link {
    color: #FFF;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    transition: opacity 0.1s ease-in;
}

.header__list-el-link:hover {
    opacity: 0.7;
}

.burger-button {
    height: 23px;
    width: 28px;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    display: none;
    z-index: 111;
}

.burger-button__line {
    display: block;
    height: 3px;
    width: 100%;
    background: #FFF;
    position: absolute;
}

.burger-button__line_first_opened {
    width: 28px;
    bottom: 10px;
    transform: rotate(45deg);
}

.burger-button__line_first {
    transition: transform 0.4s ease-in-out;
}

.burger-button__line_second {
    top: 10px;
    transition: transform 0.2s ease-in-out;
}

.burger-button__line_second_opened {
    transform: scaleY(0);
}

.burger-button__line_third {
    top: 20px;
    transition: transform 0.4s ease-in-out;
}

.burger-button__line_third_opened {
    width: 28px;
    top: 10px;
    transform: rotate(-45deg);
}

@media screen and (max-width: 1560px) {
    .header {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 835px) {
    .header__list {
        display: none;
    }

    .burger-button {
        display: block;
    }
}

@media screen and (max-width: 610px) {
    .header {
        width: calc(100% - 30px);
    }
}