@import url(./vendor/normalize.css);
@import url(./vendor/fonts/montserrat.css);

.body {
    background-color: #31394D;
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    font-family: 'Montserrat', 'sans-serif', Arial;
}
