.card__image {
    width: 100%;
    height: 400px;
    margin: 0;
    box-sizing: border-box;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.1s ease-in;
} 

.card__image:hover {
    opacity: 0.6;
}

.card:nth-child(1) {
    grid-row: 1;
    grid-column: 1/4;
}

.card:nth-child(2) {
    grid-row: 1;
    grid-column: 4/6;
}

.card:nth-child(3) {
    grid-row: 1;
    grid-column: 6/9;
}

.card:nth-child(4) {
    grid-row: 2;
    grid-column: 1/3;
}

.card:nth-child(5) {
    grid-row: 2;
    grid-column: 3/5;
}

.card:nth-child(6) {
    grid-row: 2;
    grid-column: 5/7;
}

.card:nth-child(7) {
    grid-row: 2;
    grid-column: 7/9;
}

.card:nth-child(8) {
    grid-row: 3;
    grid-column: 1/4;
}

.card:nth-child(9) {
    grid-row: 3;
    grid-column: 4/7;
}

.card:nth-child(10) {
    grid-row: 3;
    grid-column: 7/9;
}

@media screen and (max-width: 920px) {
    .card__image {
        height: 350px;
    }

    .card:nth-child(1) {
        grid-row: 1;
        grid-column: 1/4;
    }
    
    .card:nth-child(2) {
        grid-row: 1;
        grid-column: 4/6;
    }
    
    .card:nth-child(3) {
        grid-row: 1;
        grid-column: 6/9;
    }
    
    .card:nth-child(4) {
        grid-row: 2;
        grid-column: 1/3;
    }
    
    .card:nth-child(5) {
        grid-row: 3;
        grid-column: 1/4;
    }
    
    .card:nth-child(6) {
        grid-row: 2;
        grid-column: 3/7;
    }
    
    .card:nth-child(7) {
        grid-row: 2;
        grid-column: 7/9;
    }
    
    .card:nth-child(8) {
        grid-row: 3;
        grid-column: 4/9;
    }
    
    .card:nth-child(9) {
        grid-row: 4;
        grid-column: 1/5;
    }
    
    .card:nth-child(10) {
        grid-row: 4;
        grid-column: 5/9;
    }
}

@media screen and (max-width: 750px) {
    .card__image {
        height: 300px;
    }

    .card:nth-child(1) {
        grid-row: 1;
        grid-column: 1/2;
    }
    
    .card:nth-child(2) {
        grid-row: 1;
        grid-column: 2/3;
    }
    
    .card:nth-child(3) {
        grid-row: 2;
        grid-column: 1/2;
    }
    
    .card:nth-child(4) {
        grid-row: 2;
        grid-column: 2/3;
    }
    
    .card:nth-child(5) {
        grid-row: 3;
        grid-column: 1/2;
    }
    
    .card:nth-child(6) {
        grid-row: 3;
        grid-column: 2/3;
    }
    
    .card:nth-child(7) {
        grid-row: 4;
        grid-column: 1/2;
    }
    
    .card:nth-child(8) {
        grid-row: 4;
        grid-column: 2/3;
    }
    
    .card:nth-child(9) {
        grid-row: 5;
        grid-column: 1/2;
    }
    
    .card:nth-child(10) {
        grid-row: 5;
        grid-column: 2/3;
    }
}