.hairstyle {
    width: calc(100% - 440px);
    margin: 0 auto;
}

.hairstyle__title {
    max-width: 600px;
    color: #FFF;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff;
    margin: 0 auto;
}

.hairstyle__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 100px;
    margin: 60px auto 0;
}

.hairstyle__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.hairstyle__list-title {
    font-size: 30px;
    font-weight: 500;
    color: #FFF;
    margin: 0;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff3a;
}

.hairstyle__list-el {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hairstyle__list-el-title {
    color: #FFF;
    font-size: 20px;
    font-weight: 300;
    margin: 30px 0 0;
}

.hairstyle__list-el-price {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    margin: 30px 0 0;
}

.hairstyle__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 150px;
}

.hairstyle__button {
    background-color: transparent;
    border: 2px solid #FFF;
    border-radius: 10px;
    padding: 14px 0;
    width: 200px;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    transition: opacity 0.1s ease-in;
}

.hairstyle__button:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1760px) {
    .hairstyle {
        width: calc(100% - 200px);
    }
}

@media screen and (max-width: 1150px) {
    .hairstyle {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 1300px) {
    .hairstyle__title {
        font-size: 30px;
        max-width: 500px;
    }

    .hairstyle__list-title {
        font-size: 23px;
    }

    .hairstyle__list-el-title {
        max-width: 300px;
    }

    .hairstyle__list-el-title {
        font-size: 16px;
    }

    .hairstyle__list-el-price {
        font-size: 16px;
    }
}

@media screen and (max-width: 965px) {
    .hairstyle__list-el-title {
        max-width: 220px;
    }
}


@media screen and (max-width: 945px) {
    .hairstyle__list-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 890px) {
    .hairstyle__title {
        font-size: 30px;
        max-width: 450px;
    }

    .hairstyle__list-title {
        font-size: 23px;
    }

    .hairstyle__list-el-title {
        max-width: 220px;
    }

    .hairstyle__list-el-title {
        font-size: 16px;
    }

    .hairstyle__list-el-price {
        font-size: 16px;
    }
}

@media screen and (max-width: 875px) {
    .hairstyle__content {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }

    .hairstyle__title {
        font-size: 35px;
        max-width: 500px;
    }

    .hairstyle__list-title {
        font-size: 23px;
    }

    .hairstyle__list-el-title {
        max-width: 300px;
    }

    .hairstyle__list-el-title {
        font-size: 20px;
    }

    .hairstyle__list-el-price {
        font-size: 20px;
    }
}

@media screen and (max-width: 530px) {
    .hairstyle__title {
        font-size: 28px;
        max-width: 500px;
    }

    .hairstyle__list-title {
        font-size: 23px;
    }

    .hairstyle__list-el-title {
        max-width: 220px;
    }

    .hairstyle__list-el-title {
        font-size: 15px;
    }

    .hairstyle__list-el-price {
        font-size: 15px;
    }
}

@media screen and (max-width: 485px) {
    .hairstyle__title {
        font-size: 22px;
        letter-spacing: 0.1px;
        max-width: 500px;
    }
}

@media screen and (max-width: 415px) {
    .hairstyle {
        width: calc(100% - 30px);
    }

    .hairstyle__list-title {
        font-size: 20px;
    }

    .hairstyle__list-el-title {
        max-width: 200px;
    }

    .hairstyle__list-el-title {
        font-size: 12px;
    }

    .hairstyle__list-el-price {
        font-size: 12px;
    }
}