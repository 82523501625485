.page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page__title {
    margin: 0;
    color: #FFF;
    font-weight: 600;
    font-size: 150px;
}

.page__subtitle {
    margin: 0 0 50px;
    color: #FFF;
    font-weight: 500;
    font-size: 20px;
}

.page__link {
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
}