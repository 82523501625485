.price {
    max-width: 1480px;
    margin: 0 auto;
}

.price__title {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    line-height: 30.5px;
    color: #FFF;
    letter-spacing: 1px;
    text-align: left;
    margin: 0;
}

.price__title-span {
    display: inline-block;
    margin: 0;
}

.price__title-span::after {
    content: '';
    position: absolute;
    width: calc(100% - 195px);
    border-top: 2px solid #FFF;
    margin-top: .6em;
    margin-left: 32px;
    opacity: 0.13;
}

.price__content {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;
}

.price__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.price__list-el {
    transition: opacity 0.1s ease-in;
    max-width: 400px;
    flex-basis: 400px;
}

.price__list-el:hover {
    opacity: 0.7;
}

.price__list-el-link {
    text-decoration: none;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    justify-content: space-between;
}

.price__list-el-link-img-container {
    width: 87.5%;
}

 .price__list-el-link-img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 50%;
} 

.price__list-el-link-title {
    margin: 40px 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
}

.price__list-el-link-description {
    margin: 0 0 40px;
    font-size: 20px;
    font-weight: 300;
    width: 100%;
    text-align: center;
}

.price__list-el-link-button {
    background-color: transparent;
    border: 2px solid #FFF;
    border-radius: 7px;
    padding: 10px 30px;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width: 1560px) {
    .price {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 1360px) {
    .price__list {
        gap: 10px;
    }
    
    .price__list-el {
        max-width: 350px;
        flex-basis: 350px;
    }
    
    .price__list-el-link {
        height: 510px;
    }

    .price__list-el-link-img-container {
        width: 82%;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 287px;
    } 
    
    .price__list-el-link-title {
       font-size: 25px;
       margin: 20px 0;
    }
    
    .price__list-el-link-description {
        font-size: 15px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1170px) {
    .price__list-el {
        max-width: 300px;
        flex-basis: 300px;
    }
    
    .price__list-el-link {
        height: 440px;
    }

    .price__list-el-link-img-container {
        width: 73.32%;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 220px;
    } 
    
    .price__list-el-link-title {
       font-size: 20px;
       margin: 10px 0;
    }
    
    .price__list-el-link-description {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1020px) {
    .price__list-el {
        max-width: 250px;
        flex-basis: 250px;
    }
    
    .price__list-el-link {
        height: 400px;
    }

    .price__list-el-link-img-container {
        width: 76%;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 190px;
    } 
    
    .price__list-el-link-title {
       font-size: 17px;
       margin: 10px 0;
    }
    
    .price__list-el-link-description {
        font-size: 13px;
        margin-bottom: 15px;
    }

    .price__list-el-link-button {
        font-size: 12px;
        padding: 8px 25px;
    }
}

@media screen and (max-width: 870px) {
    .price__list {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
    }

    .price__list-el {
        max-width: 350px;
        width: 100%;
    }
    
    .price__list-el-link {
        height: 501px;
    }

    .price__list-el-link-img-container {
        width: 85.717%;
        height: 300px;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 100%;
    } 
    
    .price__list-el-link-title {
       font-size: 25px;
       margin: 30px 0;
    }
    
    .price__list-el-link-description {
        font-size: 17px;
        margin-bottom: 30px;
    }

    .price__list-el-link-button {
        font-size: 15px;
        padding: 10px 30px;
    }
}

@media screen and (max-width: 610px) {
    .price {
        width: calc(100% - 30px);
    }

    .price__title-span {
        font-size: 18px;
    }

    .price__title-span::after {
        margin-top: .8em;
        width: calc(100% - 156px);
    }
}

@media screen and (max-width: 450px) {
    .price__list-el {
        max-width: 250px;
        width: 100%;
    }

    .price__list-el-link {
        height: 420px;
    }

    .price__list-el-link-img-container {
        width: 80%;
        height: 200px;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 100%;
    } 
    
    .price__list-el-link-title {
       font-size: 25px;
       margin: 20px 0;
    }
    
    .price__list-el-link-description {
        font-size: 15px;
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 350px) {
    .price__list-el {
        max-width: 250px;
        width: 100%;
    }

    .price__list-el-link {
        height: 380px;
    }

    .price__list-el-link-img-container {
        width: 60%;
        height: 150px;
    }
    
     .price__list-el-link-img{
        width: 100%;
        height: 100%;
    } 
    
    .price__list-el-link-title {
       font-size: 25px;
       margin: 20px 0;
    }
    
    .price__list-el-link-description {
        font-size: 15px;
        margin-bottom: 35px;
    }
}

