.footer {
    width: 100%;
    background-color: #222;
}

.footer__content {
    max-width: 1480px;
    margin: 140px auto 0;
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.footer__logo {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    color: #FFF;
    margin: 0;
}

.footer__copyright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__copyright-copyright {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #FFF;
    margin: 0;
}

.footer__lists {
    display: flex;
    gap: 100px;
}

.footer__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer__list-el {
    margin: 0 0 18px;
}

.footer__list-el:last-of-type {
    margin-bottom: 0;
}

.footer__list-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
    margin: 0 0 18px;
}

.footer__list-el-link {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #FFF;
    text-decoration: none;
    transition: opacity 0.1s ease-in;
}

.footer__list-el-link:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1560px) {
    .footer__content {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 1015px) {
    .footer__lists {
        gap: 60px;
    }

    .footer__list-title {
        font-size: 15px;
    }

    .footer__list-el-link {
        font-size: 15px;
    }

    .footer__copyright-copyright {
        font-size: 15px;
    }
}

@media screen and (max-width: 705px) {
    .footer__content {
        flex-direction: column;
    }

    .footer__copyright {
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        padding-bottom: 30px;
    }

    .footer__copyright-copyright {
        margin-top: 10px;
    }

    .footer__lists {
        gap: 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer__list {
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4); 
    }

    .footer__list:last-of-type {
        border: none;
    }
}

@media screen and (max-width: 610px) {
    .footer__content {
        width: calc(100% - 30px);
    }
}