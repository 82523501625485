@font-face {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/montserrat-light/Montserrat-Light.ttf");
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/montserrat-regular/Montserrat-Regular.ttf");
  }

  @font-face {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/montserrat-medium/Montserrat-Medium.ttf");
  }

  @font-face {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/montserrat-semibold/Montserrat-SemiBold.ttf");
  }