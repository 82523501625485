.upward {
    position: fixed;
    background-image: url('../../images/upward.png');
    background-size: contain;
    cursor: pointer;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
    transition: opacity 0.1s ease-in;
    visibility: hidden;
    opacity: 0;
}

.upward:hover {
    opacity: 0.7;
}

.upward_active {
    visibility: visible;
    opacity: 1;
}