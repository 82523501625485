.burger {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s;
    z-index: 100;
}

.burger_active {
    opacity: 1;
    pointer-events: all;
}

.burger__content {
    width: 65vw;
    height: 100vh;
    background-color: rgb(29, 29, 29);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;
    transform: translateX(100%);
}

.burger__content_active {
    transform: translateX(0);
}

.burger__list {
    list-style: none;
    margin: 60px 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.burger__list-el {
    margin: 30px 0 0;
}

.burger__list-el-link {
    color: #FFF;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    transition: opacity 0.1s ease-in;
}

.burger__list-el-link:hover {
    opacity: 0.7;
}

.burger__button {
    background-color: #3C91CA;
    border: none;
    border-radius: 10px;
    padding: 14px 48px;
    width: 200px;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    transition: opacity 0.1s ease-in;
    margin: 0 0 60px;
}

.burger__button:hover {
    opacity: 0.7;
}

@media screen and (max-width: 440px){
    .burger__content {
        width: 100vw;
    }
}